import { FC, lazy as reactLazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { SignOut } from 'components/SignOut'

import {
  companyProfile,
  dashboardPath,
  destinationRoutingsPath,
  destinationsPath,
  dispatchSearchPath,
  myAccountPath,
  orderDetailsPath,
  orderPath,
  programPath,
  programsPath,
  quoteDetailsPath,
  reportGridPath,
  reportQueuePath,
  reportSchedulePath,
  reportsPath,
  reportSubmissionPath,
  signOutPath,
  uploadsPath,
  userAdminPath,
  viewsPath,
} from './Routes'

import { Page } from 'components/Page'
import { useTheme } from 'contexts/ThemeContext'
import { useAppContext } from 'hooks/useAppContext.ts'
import { Redirect } from 'react-router'
import { AddAccountErrorActions } from 'types'
import { GUEST_ROUTES, UNAUTHORIZED_ROUTES } from '../../Routes'
import { ExpiredPasswordForm } from '../ExpiredPasswordForm'
import { ForgotPasswordPage } from '../ForgotPasswordPage'
import { ForgotPasswordSuccessPage } from '../ForgotPasswordSuccessPage'
import { LogInModal } from '../LogInModal'
import { LogInPage } from '../LogInPage'
import { ProtectedRoute } from '../ProtectedRoute'
import { ResetPasswordPage } from '../ResetPasswordPage'
import { UnauthorizedPage } from '../UnauthorizedPage'
import { UnAuthorizedResetPWSuccess } from '../UnauthorizedResetPWSuccess'
import { ErrorBoundary } from './ErrorBoundary'
import GuestOutOfServiceAreaPage from 'pages/OrderPages/pages/GuestOutOfServiceAreaPage/GuestOutOfServiceAreaPage'

const lazy: typeof reactLazy = fn => {
  return reactLazy(() =>
    fn().catch(error => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )
}

const CompanyProfilePage = lazy(() => import('pages/CompanyProfile'))
const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const DestinationRoutingsPage = lazy(() => import('pages/DestinationRoutingsPage'))
const DestinationsPage = lazy(() => import('pages/DestinationsPage'))
const DispatchSearchResultsPage = lazy(() => import('pages/DispatchSearchResultsPage'))
const DispatchesPage = lazy(() => import('pages/DispatchesPage'))
const GuestAssetsPage = lazy(() => import('pages/OrderPages/pages/GuestAssetsPage/GuestAssetsPage'))
const GuestBoxesPage = lazy(() => import('pages/OrderPages/pages/GuestBoxesPage/GuestBoxesPage'))
const GuestCheckoutSummaryPage = lazy(
  () => import('pages/OrderPages/pages/GuestCheckoutSummaryPage/GuestCheckoutSummaryPage'),
)
const GuestPalletsPage = lazy(
  () => import('pages/OrderPages/pages/GuestPalletsPage/GuestPalletsPage'),
)
const GuestPaymentPage = lazy(
  () => import('pages/OrderPages/pages/GuestPaymentPage/GuestPaymentPage'),
)
const GuestPickupLocationPage = lazy(
  () => import('pages/OrderPages/pages/GuestPickupLocationPage/GuestPickupLocationPage'),
)
const GuestPricePage = lazy(() => import('pages/OrderPages/pages/GuestPricePage/GuestPricePage'))
const GuestRequestedPickupPage = lazy(
  () => import('pages/OrderPages/pages/GuestRequestedPickupPage/GuestRequestedPickupPage'),
)
const ImpersonatePage = lazy(() => import('pages/ImpersonatePage'))
const MyAccountPage = lazy(() => import('pages/MyAccountPage'))
const OrderDetailsPage = lazy(() => import('pages/OrderDetailsPage'))
const OrderPages = lazy(() => import('pages/OrderPages'))
const ProgramPage = lazy(() => import('pages/ProgramPage'))
const ProgramsPage = lazy(() => import('pages/ProgramsPage'))
const QuoteDetailsPage = lazy(() => import('pages/QuoteDetailsPage'))
const RedirectPage = lazy(() => import('pages/RedirectPage'))
const ReportGridPage = lazy(() => import('pages/ReportGridPage'))
const ReportQueuePage = lazy(() => import('pages/ReportQueuePage'))
const ReportSchedulePage = lazy(() => import('pages/ReportSchedulePage'))
const ReportSubmissionPage = lazy(() => import('pages/ReportSubmissionPage'))
const ReportsPage = lazy(() => import('pages/ReportsPage'))
const UpdateAccountPage = lazy(() => import('pages/UpdateAccountPage'))
const UploadsPage = lazy(() => import('pages/UploadsPage'))
const UserAdminPage = lazy(() => import('pages/UserAdminPage'))

const LoadingPage: FC = () => <Page />

export const Router = () => {
  const { user } = useAppContext()
  const { brandPath } = useTheme()

  return (
    <BrowserRouter basename={brandPath} key={brandPath}>
      <ErrorBoundary>
        <Suspense fallback={<LoadingPage />}>
          <LogInModal />

          {user?.authAction?.name === AddAccountErrorActions.REDIRECT_UPDATE_ACCOUNT ? (
            <UpdateAccountPage />
          ) : (
            <Switch>
              <Route exact path={UNAUTHORIZED_ROUTES.createAccount}>
                <CreateAccountPage />
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.alternativeLogin}>
                <Redirect to={UNAUTHORIZED_ROUTES.login} />
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.login}>
                <LogInPage />
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.passwordExpired}>
                <UnauthorizedPage>
                  <ExpiredPasswordForm />
                </UnauthorizedPage>
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.forgotPassword}>
                <UnauthorizedPage maxWidth={525}>
                  <ForgotPasswordPage />
                </UnauthorizedPage>
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.forgotPasswordSuccess}>
                <UnauthorizedPage>
                  <ForgotPasswordSuccessPage />
                </UnauthorizedPage>
              </Route>

              <Route path={UNAUTHORIZED_ROUTES.impersonate()}>
                <UnauthorizedPage>
                  <ImpersonatePage />
                </UnauthorizedPage>
              </Route>

              <Route exact path={`${UNAUTHORIZED_ROUTES.resetPassword}/:uid`}>
                <UnauthorizedPage>
                  <ResetPasswordPage />
                </UnauthorizedPage>
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.resetPasswordSuccess}>
                <UnauthorizedPage>
                  <UnAuthorizedResetPWSuccess />
                </UnauthorizedPage>
              </Route>

              <Route path={UNAUTHORIZED_ROUTES.redirect()}>
                <RedirectPage />
              </Route>

              <Route path={GUEST_ROUTES.assets}>
                <GuestAssetsPage />
              </Route>

              <Route path={GUEST_ROUTES.boxes}>
                <GuestBoxesPage />
              </Route>

              <Route path={GUEST_ROUTES.outOfServiceArea}>
                <GuestOutOfServiceAreaPage />
              </Route>

              <Route path={GUEST_ROUTES.pallets}>
                <GuestPalletsPage />
              </Route>

              <Route path={GUEST_ROUTES.pickupLocation}>
                <GuestPickupLocationPage />
              </Route>

              <Route path={GUEST_ROUTES.requestedPickup}>
                <GuestRequestedPickupPage />
              </Route>

              <Route path={GUEST_ROUTES.checkoutSummary}>
                <GuestCheckoutSummaryPage />
              </Route>

              <Route path={GUEST_ROUTES.price}>
                <GuestPricePage />
              </Route>

              <Route path={GUEST_ROUTES.payment}>
                <GuestPaymentPage />
              </Route>

              <ProtectedRoute path={dashboardPath} exact>
                <DashboardPage />
              </ProtectedRoute>
              <ProtectedRoute path={destinationRoutingsPath} exact>
                <DestinationRoutingsPage />
              </ProtectedRoute>
              <ProtectedRoute path={destinationsPath} exact>
                <DestinationsPage />
              </ProtectedRoute>
              <ProtectedRoute path={dispatchSearchPath} exact>
                <DispatchSearchResultsPage />
              </ProtectedRoute>
              <ProtectedRoute path={myAccountPath} exact>
                <MyAccountPage />
              </ProtectedRoute>
              <ProtectedRoute path={orderDetailsPath()} exact>
                <OrderDetailsPage />
              </ProtectedRoute>
              <ProtectedRoute path={orderPath}>
                <OrderPages />
              </ProtectedRoute>
              <ProtectedRoute path={quoteDetailsPath()} exact>
                <QuoteDetailsPage />
              </ProtectedRoute>
              <ProtectedRoute path={reportQueuePath} exact>
                <ReportQueuePage />
              </ProtectedRoute>
              <ProtectedRoute path={userAdminPath} exact>
                <UserAdminPage />
              </ProtectedRoute>
              <ProtectedRoute path={viewsPath}>
                <DispatchesPage />
              </ProtectedRoute>
              <ProtectedRoute path={reportSchedulePath} exact>
                <ReportSchedulePage />
              </ProtectedRoute>
              <Route path={reportSubmissionPath()}>
                <ReportSubmissionPage />
              </Route>
              <ProtectedRoute path={reportsPath} exact>
                <ReportsPage />
              </ProtectedRoute>
              <ProtectedRoute path={reportGridPath} exact>
                <ReportGridPage />
              </ProtectedRoute>
              <ProtectedRoute path={uploadsPath} exact>
                <UploadsPage />
              </ProtectedRoute>
              <ProtectedRoute path={programsPath} exact>
                <ProgramsPage />
              </ProtectedRoute>
              <ProtectedRoute path={programPath} exact>
                <ProgramPage />
              </ProtectedRoute>

              <ProtectedRoute path={signOutPath} exact>
                <SignOut />
              </ProtectedRoute>

              <ProtectedRoute path={companyProfile} exact>
                <CompanyProfilePage />
              </ProtectedRoute>

              <Redirect exact from="/" to={viewsPath} />
              <Route>
                <Page>
                  <h1>Not Found</h1>
                </Page>
              </Route>
            </Switch>
          )}
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
